export async function connect(): Promise<string> {
	if (!(window as any).rabet) {
		throw new Error('Rabet is not installed');
	}

	let result = await (window as any).rabet.connect();
	return result.publicKey;
}

export async function sign(xdr: string): Promise<string> {
	let result = await (window as any).rabet.sign(xdr, 'mainnet');
	return result.xdr;
}
