import React from 'react';
import styled from 'styled-components';
import { Header } from 'components';
import comingSoon from '../assets/images/airdrop-coming-soon.svg';
import { Grid } from '@mui/material';
import { Airdrop } from './airdrop';

const Container = styled.div`
	width: 100%;
	min-height: 100vh;
  overflow-x: hidden;
	background: ${({ theme }) => theme.colors.background};
  padding: 1px;
`;

const Content = styled.div`
	width: 100%;
	min-height: calc(100% - 6.25rem);
	padding-bottom: 6.25rem;
	display: flex;
	justify-content: center;
	align-items: center;

	${({ theme }) => theme.mediaWidth.upToSmall`
		flex-direction: column;
	`}
`;

const BigDiv = styled.div`
	align-items: center;
	background: ${({ theme }) => theme.colors.white};
	border-radius: 25px;
	padding: 2rem;
	margin-top: 1rem;
	margin-left: auto;
	margin-right: auto;
`;

const IconWrapper = styled.div`
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 2rem;
	margin-bottom: 2rem;
	border-radius: 50%;
	align-items: center;
	justify-content: center;

	img {
		width: 100%;
	}
`;

const Text1 = styled.div`
	width: 90%;
	font-size: 2rem;
	line-height: 2.5rem;
	margin-left: auto;
	margin-right: auto;
	font-weight: bold;
	text-align: center;
	color: ${({ theme }) => theme.colors.black};
`;

const Text2 = styled.div`
	width: 85%;
	margin-left: auto;
	margin-right: auto;
	font-size: 1rem;
	line-height: 1.5rem;
	text-align: center;
	color: ${({ theme }) => theme.colors.black};
`;

export const Main = () => {
	return (
		<Container>
			<Header />
			<Content>
        <Grid container>
          <Grid item xs={false} md={1} lg={2} />
          <Grid item xs={12} md={10} lg={8}>
            <Airdrop />
          </Grid>
          <Grid item xs={false} md={1} lg={2} />
        </Grid>
			</Content>
		</Container>
	);
};
