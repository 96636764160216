import React from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";
import { Divider } from "components";
import { SectionTitle } from "components";
import { Eligibility } from "./eligibility";
import { DayZero } from "./dayZero";

const BigDiv = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 25px;
  padding: 2rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

const Text1 = styled.div`
  width: 90%;
  font-size: 2rem;
  line-height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
`;

const Text2 = styled.div`
  margin-right: auto;
  margin-left: 20px;
  margin-bottom: 20px;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
`;

const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 2rem;
`;

export const Airdrop = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={1} md={2}></Grid>
      <Grid item xs={10} md={8}>
        <Eligibility />
      </Grid>
      <Grid item xs={1} md={2}></Grid>
      <Grid item xs={12}>
        <Divider />
        <SectionTitle title="Day-Zero Staking" />
        <Text2>
          <p>
            Users can elect to enter Day-Zero Staking for 3, 6, or 12 months. By
            Staking on Day-Zero, you will boost the amount of YBX you receive
            from the airdrop! The YBX you get from the airdrop will be
            automatically staked in the protocol. Further, these Day-Zero stakes
            cannot be withdrawn early to prevent users from gaming the airdrop.
          </p>
        </Text2>
      </Grid>
    </Grid>
  );
};
