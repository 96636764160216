import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
	position: relative;
	background: ${({ theme }) => theme.colors.white};
	border-radius: 40px;
	box-shadow: 0 3px 6px ${({ theme }) => theme.colors.shadow};
	padding: 20px 40px;
	box-sizing: border-box;
`;

export const Card: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
	return <StyledCard {...props}>{children}</StyledCard>;
};
