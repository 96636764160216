import React from 'react';
import styled from 'styled-components';
import { Card } from 'components';

import closeImg from 'assets/images/icn_close.svg';

const Container = styled.div<{ open: boolean }>`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: ${({ open }) => (open ? 'flex' : 'none')};
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	z-index: 10;
`;

const ContentCard = styled(Card)<{ width?: number; height?: number }>`
	${({ width }) => (width ? `width: ${width}px;` : '')}
	${({ height }) => (height ? `height: ${height}px;` : '')}
`;

const CloseButton = styled.img`
	position: absolute;
	cursor: pointer;
	top: 20px;
	right: 20px;
`;

export interface IModalProps extends React.HTMLAttributes<HTMLElement> {
	open: boolean;
	onClose: () => void;
	width?: number;
	height?: number;
}

export const Modal: React.FC<IModalProps> = ({ open, onClose, width, height, children }) => {
	return (
		<Container onClick={onClose} open={open}>
			<ContentCard onClick={e => e.stopPropagation()} width={width} height={height}>
				<CloseButton src={closeImg} alt="" onClick={onClose} />
				{children}
			</ContentCard>
		</Container>
	);
};
