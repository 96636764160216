import freighterApi from '@stellar/freighter-api';

export async function connect(): Promise<string> {
	let active = freighterApi.isConnected();
	if (!active) throw new Error('Freighter is not installed');

	return await freighterApi.getPublicKey();
}

export async function sign(xdr: string): Promise<string> {
	let signedTransaction = await freighterApi.signTransaction(xdr, "PUBLIC");
	return signedTransaction;
}
