import React, { useContext } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { TransactionBuilder } from 'stellar-sdk';
import { WalletType } from 'types';
import { SubmitTransaction } from 'utils';
import { albedo, freighter, rabet } from 'utils/wallet';

export interface IWalletContext {
	connected: boolean;
	walletAddress: Maybe<string>;
	walletType: Maybe<WalletType>;
	connect: (_walletType: WalletType) => void;
	signTransaction: (xdr: string) => Promise<Maybe<string>>;
}

const WalletContext = React.createContext<Maybe<IWalletContext>>(undefined);

export const WalletProvider = ({ children = null as any }) => {
	const [connected, setConnected] = useState<boolean>(false);
	const [walletAddress, setWalletAddress] = useState<Maybe<string>>(undefined);
	const [walletType, setWalletType] = useState<Maybe<WalletType>>(undefined);

	/**
	 * Connects a browser wallet by fetching the public key from the wallet.
	 * @param _walletType - The wallet we are trying to connect
	 * @returns The public key of the wallet
	 */
	async function connect(_walletType: WalletType) {
		try {
			let address = null;
			switch (_walletType) {
				case WalletType.Freighter:
					address = await freighter.connect();
					break;
				case WalletType.Albedo:
					address = await albedo.connect();
					break;
				case WalletType.Rabet:
					address = await rabet.connect();
					break;
			}
			setWalletAddress(address);
			setWalletType(_walletType);
			setConnected(true);
		} catch (e) {
			console.error(e);
			setWalletAddress(undefined);
			setConnected(false);
			throw e;
		}
	}

	/**
	 * Signs a transaction with a connected wallet.
	 * @param xdr = The xdr-encoded transaction envelope to sign.
	 * @returns The signed_xdr of the transaction
	 */
	async function signTransaction(xdr: string): Promise<Maybe<string>> {
		try {
      let signedXdr = '';
			switch (walletType) {
				case WalletType.Freighter:
					signedXdr = await freighter.sign(xdr);
          break;
				case WalletType.Albedo:
					signedXdr = await albedo.sign(xdr);
          break;
				case WalletType.Rabet:
					signedXdr = await rabet.sign(xdr);
          break;
			}
      console.log('signedXdr', signedXdr);
      return await SubmitTransaction(signedXdr);
		} catch (e: any) {
			console.error(e);
      if (e instanceof Error) {
        toast.error(`Transaction was unable to be submitted: ${e.message}`);
      } else {
        toast.error('Unable to complete your request. Please refresh the page and try again later.');
      }
			return undefined;
		}
	}

	return (
		<WalletContext.Provider value={{ connected, walletAddress, walletType, connect, signTransaction }}>
			{children}
		</WalletContext.Provider>
	);
};

export const useWallet = () => {
	const context = useContext(WalletContext);

	if (!context) {
		throw new Error('Component rendered outside the provider tree');
	}

	return context;
};
