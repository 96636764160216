import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input<{ error?: boolean }>`
	border-radius: 40px;
	border: 1px solid ${({ theme, error }) => (error ? theme.colors.error : theme.colors.dark_grey)};
	padding: 8px 24px;
	font-size: 20px;
	line-height: 26px;
	color: ${({ theme }) => theme.colors.black};
	outline: none;

	&:placeholder {
		color: ${({ theme }) => theme.colors.light_grey};
	}
`;

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
	error?: boolean;
}

export const Input: React.ForwardRefExoticComponent<IProps> = React.forwardRef(
	({ children, error, ...props }, ref: React.LegacyRef<HTMLInputElement>) => {
		return (
			<StyledInput ref={ref as any} error={error} {...props}>
				{children}
			</StyledInput>
		);
	}
);
