import React from 'react';
import styled from 'styled-components';

import ArrowImg from 'assets/images/icn_arrow_down.svg';

const Container = styled.div`
	position: relative;
	border-radius: 40px;
	border: 1px solid ${({ theme }) => theme.colors.dark_grey};
`;

const StyledSelect = styled.select`
	padding: 8px 40px 8px 24px;
	width: 100%;
	box-sizing: border-box;
	font-size: 20px;
	line-height: 26px;
	color: ${({ theme }) => theme.colors.black};
	border: none;
	border-radius: 40px;
`;

const ArrowIcon = styled.img`
	position: absolute;
	top: calc(50% - 7px);
	height: 14px;
	right: 10px;
	cursor: pointer;
`;

interface IProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
	options: Array<string>;
}

export const Select: React.FC<IProps> = ({ options, className, ...props }) => {
	return (
		<Container className={className}>
			<ArrowIcon src={ArrowImg} alt="" />

			<StyledSelect placeholder="Size" {...props}>
				{options.map((value, idx) => (
					<option key={idx}>{value}</option>
				))}
			</StyledSelect>
		</Container>
	);
};
