import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Card, Input } from "components";
import { useWallet } from "contexts/wallet";
import { WalletType } from "types";
import { Alert, Stack, LinearProgress } from "@mui/material";
import BigNumber from "bignumber.js";

import { GetStakingForWallet, GetWalletPayoutEntries } from "utils";
import { StrKey } from "stellar-sdk";
import { TooltipButton } from "components/Button/TooltipButton";

const Container = styled(Card)`
  width: 100%;
  min-height: 31rem;
  margin-top: 2rem;

  > button,
  input {
    width: 100%;
    height: 4rem;
    font-size: 1.5rem;
    line-height: 120%;
    margin-top: 2rem;
  }
`;

// betaPerEntry 0.54639594326081268665
// communityPerEntry 57.84610044975343099683
// trustlinePerEntry 28.60711935843766985477
const BETA_ENTRY_YBX = new BigNumber("0.54639594326081268665");
const COMMUNITY_ENTRY_YBX = new BigNumber("57.84610044975343099683");
const TRUSTLINE_ENTRY_YBX = new BigNumber("28.60711935843766985477");

export const Eligibility = () => {
  const { walletAddress, connected } = useWallet();

  const [eligible, setEligible] = useState<Maybe<boolean>>(undefined);
  const [community, setCommunity] = useState<Maybe<string>>(undefined);
  const [beta, setBeta] = useState<Maybe<string>>(undefined);
  const [trustline, setTrustline] = useState<Maybe<string>>(undefined);
  const [staking, setStaking] = useState<Maybe<number>>(undefined);
  const [dq, setDq] = useState<Maybe<boolean>>(undefined);
  const [newStake, setNewStake] = useState<boolean>(false);

  const [inputKey, setInputKey] = useState<string>("");
  const [inputDisabled, setInputDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (walletAddress) {
      getEligibility(walletAddress);
    } else {
      setEligible(undefined);
      setCommunity(undefined);
      setBeta(undefined);
      setStaking(undefined);
      setDq(undefined);
    }
  }, [walletAddress]);

  const getShortenWalletAddress = () => {
    if (!walletAddress) {
      return "";
    }

    return `0x${walletAddress.substr(0, 4)}...${walletAddress.substr(
      walletAddress.length - 4,
      walletAddress.length
    )}`;
  };

  const handleSetInputKey = (publicKey: string) => {
    console.log("setting input", publicKey);
    if (StrKey.isValidEd25519PublicKey(publicKey)) {
      setInputDisabled(false);
      setInputKey(publicKey);
    } else {
      setInputDisabled(true);
      setInputKey(publicKey);
    }

    setEligible(undefined);
    setCommunity(undefined);
    setBeta(undefined);
    setStaking(undefined);
    setDq(undefined);
  };

  const handleInputPublicKey = async (publicKey: string) => {
    setLoading(true);
    if (StrKey.isValidEd25519PublicKey(publicKey)) {
      await getEligibility(publicKey);
    }
  };

  const getEligibility = async (publicKey: string) => {
    setLoading(true);
    setEligible(undefined);
    setCommunity(undefined);
    setBeta(undefined);
    setStaking(undefined);
    setDq(undefined);
    let payouts = await GetWalletPayoutEntries(publicKey);
    if (payouts === undefined) {
      // no eligibility
      setEligible(false);
      return;
    } else {
      // eligible! find out exactly how
      let staking = payouts.staking;
      let multiple = 1;
      let betaMultiple = 1;
      if (staking) {
        multiple = 10;
        betaMultiple = payouts.beta_paid ? 1 : 10;
        setStaking(payouts.stake_period);
        setNewStake(payouts.beta_paid);
      } else {
        setStaking(0);
      }

      setDq(payouts.dq);
      setEligible(true);
      let trustlineYbx = new BigNumber(multiple).times(TRUSTLINE_ENTRY_YBX);
      setTrustline(trustlineYbx.toFixed(7, BigNumber.ROUND_FLOOR));
      let communityYbx = new BigNumber(payouts.community_entries)
        .times(multiple)
        .times(COMMUNITY_ENTRY_YBX);
      setCommunity(communityYbx.toFixed(7, BigNumber.ROUND_FLOOR));
      if (payouts.beta_user) {
        let betaYbx = new BigNumber(payouts.beta_entries)
          .times(betaMultiple)
          .times(BETA_ENTRY_YBX);
        setBeta(betaYbx.toFixed(7, BigNumber.ROUND_FLOOR));
      }
    }

    setLoading(false);
  };

  return (
    <Container>
      <h3 style={{ margin: "2rem 0" }}>Check Airdrop</h3>
      <p>
        Enter your Stellar Public Key below to check your airdrop status and
        Day-Zero staking status.
      </p>
      {connected ? (
        <Button
          style={{ color: "#FFFFFF", background: "#37B24A" }}
          onClick={() => getEligibility(walletAddress ?? "")}
        >
          Check {getShortenWalletAddress()}
        </Button>
      ) : (
        <>
          <Input
            type=""
            placeholder="Public Key (G...)"
            onChange={(e) => handleSetInputKey(e.target.value)}
          />
          <TooltipButton
            disabled={inputDisabled}
            style={{
              width: "100%",
              height: "4rem",
              marginTop: "2.5rem",
              marginBottom: "1rem",
              fontSize: "1.5rem",
              color: "#FFFFFF",
              background: "#37B24A",
            }}
            errorMessage="Please enter a valid Stellar Public Key"
            onClick={() => handleInputPublicKey(inputKey)}
          >
            Check
          </TooltipButton>
        </>
      )}
      {eligible !== true ? (
        eligible === undefined ? (
          loading && <LinearProgress style={{ marginTop: "1rem" }} />
        ) : (
          <Stack sx={{ width: "100%", marginTop: "1rem" }} spacing={2}>
            <Alert severity="error">
              Your wallet does not qualify for any airdrop reward.
            </Alert>
          </Stack>
        )
      ) : (
        <Stack sx={{ width: "100%", marginTop: "1rem" }} spacing={2}>
          {dq ? (
            <Alert severity="warning">
              This account has been flagged for farming the airdrop. It has been
              disqualified from future airdrops. One account from each farming
              group was not flagged and will receive the airdrop.
            </Alert>
          ) : (
            <>
              {community !== undefined && parseFloat(community) > 0 && (
                <Alert severity="success">
                  You qualify for the community airdrop (Airdrop 1)! Total YBX:{" "}
                  {community}.
                </Alert>
              )}
              {beta !== undefined && parseFloat(beta) > 0 && (
                <Alert severity="success">
                  You qualify for the beta airdrop (Airdrop 2)! Total YBX:{" "}
                  {beta}.
                </Alert>
              )}
              {trustline !== undefined && (
                <Alert severity="success">
                  You qualify for the ecosystem airdrop (Airdrop 3)! Total YBX:{" "}
                  {trustline}.
                </Alert>
              )}
              {staking ? (
                <>
                  {newStake ? (
                    <Alert severity="success">
                      You are Day-Zero Staking for the Community and Ecosystem
                      Airdrops! Your lockup period is: {staking} months!
                      distribution.
                    </Alert>
                  ) : (
                    <Alert severity="success">
                      You are Day-Zero Staking for all eligible airdrops! Your
                      lockup period is: {staking} months!
                    </Alert>
                  )}
                  <Alert severity="info">
                    Your tokens will be distributed as a Day Zero Stake once the
                    protocol launches. You will NOT get tokens during the
                    airdrop distribution.
                  </Alert>
                </>
              ) : (
                <Alert severity="warning">You are NOT Day-Zero staking</Alert>
              )}
            </>
          )}
        </Stack>
      )}
    </Container>
  );
};
