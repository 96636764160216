import { Payout } from "types";
import axios from 'axios';
import { Asset, Server, TransactionBuilder, Networks, Operation, Transaction } from "stellar-sdk";

const horizon = new Server('https://horizon.stellar.org');

export async function GetWalletPayoutEntries(walletAddress: string): Promise<Maybe<Payout>> {
  try {
    let result = await axios.get('https://9bir9klz1f.execute-api.us-east-1.amazonaws.com/default/airdrop-reader', {
      params: {
        publicKey: walletAddress
      }
		});
    if (result.status != 500) {
      if (result.data?.airdrop === false) {
        return undefined;
      }
      return result.data as Payout;
    }
    throw new Error(JSON.stringify(result.data));
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function GetStakingForWallet(walletAddress: string): Promise<number> {
  try {
    let account = await horizon.loadAccount(walletAddress);

    let dayZero3 = false;
    let dayZero6 = false;
    let dayZero12 = false;
    for (const bl of account.balances) {
      if (bl.asset_type === 'credit_alphanum12' && bl.asset_issuer === 'GBUYYBXWCLT2MOSSHRFCKMEDFOVSCAXNIEW424GLN666OEXHAAWBDYMX') {
        if (bl.asset_code === 'Day0Stake3') {
          dayZero3 = true;
        } else if (bl.asset_code === 'Day0Stake6') {
          dayZero6 = true;
        } else if (bl.asset_code === 'Day0Stake12') {
          dayZero12 = true;
        }
      }
    }

    if (dayZero12) return 12;
    if (dayZero6) return 6;
    if (dayZero3) return 3;

    return 0;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function BuildTransactionForStaking(walletAddress: string, type: number): Promise<Maybe<string>> {
  try {
    let account = await horizon.loadAccount(walletAddress);
    let txBuilder = new TransactionBuilder(account, {
      fee: '1000',
      networkPassphrase: Networks.PUBLIC,
      timebounds: {
        maxTime: Math.floor(Date.now() / 1000) + 5 * 60,
        minTime: 0,
      },
    });
  
    if (type === 0) {
      // stake 3 months
      txBuilder.addOperation(
        Operation.changeTrust({
          asset: new Asset('Day0Stake3', 'GBUYYBXWCLT2MOSSHRFCKMEDFOVSCAXNIEW424GLN666OEXHAAWBDYMX')
        })
      );
    } else if (type === 1) {
      // stake 6 months
      txBuilder.addOperation(
        Operation.changeTrust({
          asset: new Asset('Day0Stake6', 'GBUYYBXWCLT2MOSSHRFCKMEDFOVSCAXNIEW424GLN666OEXHAAWBDYMX')
        })
      );
    } else if (type === 2) {
      // stake 12 months
      txBuilder.addOperation(
        Operation.changeTrust({
          asset: new Asset('Day0Stake12', 'GBUYYBXWCLT2MOSSHRFCKMEDFOVSCAXNIEW424GLN666OEXHAAWBDYMX')
        })
      );
    } else {
      return undefined;
    }
  
    return txBuilder.build().toXDR();
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function BuildRemoveStakingTrustlines(walletAddress: string, type: number): Promise<Maybe<string>> {
  try {
    let account = await horizon.loadAccount(walletAddress);
    let txBuilder = new TransactionBuilder(account, {
      fee: '1000',
      networkPassphrase: Networks.PUBLIC,
      timebounds: {
        maxTime: Math.floor(Date.now() / 1000) + 5 * 60,
        minTime: 0,
      },
    });
  
    let dayZero3 = false;
    let dayZero6 = false;
    let dayZero12 = false;
    for (const bl of account.balances) {
      if (bl.asset_type === 'credit_alphanum12' && bl.asset_issuer === 'GBUYYBXWCLT2MOSSHRFCKMEDFOVSCAXNIEW424GLN666OEXHAAWBDYMX') {
        if (bl.asset_code === 'Day0Stake3') {
          dayZero3 = true;
        } else if (bl.asset_code === 'Day0Stake6') {
          dayZero6 = true;
        } else if (bl.asset_code === 'Day0Stake12') {
          dayZero12 = true;
        }
      }
    }
  
    if (dayZero3) {
      // stake 3 months
      txBuilder.addOperation(
        Operation.changeTrust({
          asset: new Asset('Day0Stake3', 'GBUYYBXWCLT2MOSSHRFCKMEDFOVSCAXNIEW424GLN666OEXHAAWBDYMX'),
          limit: '0',
        })
      );
    }
    
    if (dayZero6) {
      // stake 6 months
      txBuilder.addOperation(
        Operation.changeTrust({
          asset: new Asset('Day0Stake6', 'GBUYYBXWCLT2MOSSHRFCKMEDFOVSCAXNIEW424GLN666OEXHAAWBDYMX'),
          limit: '0',
        })
      );
    }
    
    if (dayZero12) {
      // stake 12 months
      txBuilder.addOperation(
        Operation.changeTrust({
          asset: new Asset('Day0Stake12', 'GBUYYBXWCLT2MOSSHRFCKMEDFOVSCAXNIEW424GLN666OEXHAAWBDYMX'),
          limit: '0',
        })
      );
    }
  
    if (dayZero3 || dayZero6 || dayZero12) {
      return txBuilder.build().toXDR();
    } else {
      return undefined;
    }
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function SubmitTransaction(envelopeXdr: string): Promise<Maybe<string>> {
  console.log('Building tx...');
  let tx = TransactionBuilder.fromXDR(envelopeXdr, Networks.PUBLIC);
  if (tx instanceof Transaction) {
    try {
      let result = await horizon.submitTransaction(tx);
      console.log('result', result.hash);
      return result.hash;
    } catch (e: any) {
      const { result_codes } = e.response.data?.extras || {};
      throw new Error(JSON.stringify(result_codes, null, 2));
    }
  } else {
    return undefined;
  }
}
