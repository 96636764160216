import React from 'react';
import styled from 'styled-components';
import { Button } from 'components';

import logoImg from 'assets/images/logo.svg';

const Container = styled.div`
	width: 100%;
	height: 6.25rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 5rem;
	box-sizing: border-box;

	${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 1rem;
  `}
`;

const LogoImg = styled.img`
	height: 3rem;
`;

export const Header = () => {
	return (
		<Container>
			<a target="script3" href="https://script3.io/">
				<LogoImg src={logoImg} alt="" />
			</a>
      <Button style={{background:'#9CC6EA'}} onClick={() => window.open('https://www.script3.io/ybx-dzs', 'help')}>
				Airdrop Support
			</Button>
		</Container>
	);
};
