import React from 'react';
import styled from 'styled-components';

const StyledDivider = styled.hr`
	margin: 2rem;
`;

export const Divider = () => {
	return <StyledDivider />;
};
