export enum WalletType {
  Freighter,
  Albedo,
  Rabet,
}

// account_id varchar(64) NOT NULL,
// staking boolean NOT NULL,
// stake_period integer NOT NULL,
// community_entries varchar(64) NOT NULL,
// beta_user boolean NOT NULL,
// beta_entries varchar(64) NOT NULL,
// updated_utc TIMESTAMP NOT NULL,
// PRIMARY KEY (account_id)
export interface Payout {
  account_id: string;
  staking: boolean;
  stake_period: number;
  community_entries: string;
  beta_user: boolean;
  beta_entries: string;
  updated_utc: Date;
  beta_paid: boolean;
  dq: boolean;
}
