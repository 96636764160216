import React from 'react';
import { ToastContainer } from 'react-toastify';
import ThemeProvider, { ThemedGlobalStyle } from 'theme';
import { Main } from 'pages';
import { WalletProvider } from 'contexts/wallet';

import 'react-toastify/dist/ReactToastify.css';

function App() {
	return (
		<ThemeProvider>
			<ThemedGlobalStyle />

			<WalletProvider>
					<Main />
			</WalletProvider>

			<ToastContainer />
		</ThemeProvider>
	);
}

export default App;
