import React from 'react';
import {
	createGlobalStyle,
	DefaultTheme,
	css,
	ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';
import { Colors } from './styled';

const MEDIA_WIDTHS = {
	upToExtraSmall: 500,
	upToSmall: 720,
	upToMedium: 960,
	upToLarge: 1280,
	upToExtraLarge: 1400,
};

const mediaWidthTemplates: {
	[width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
	(accumulator as any)[size] = (a: any, b: any, c: any) => css`
		@media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
			${css(a, b, c)}
		}
	`;
	return accumulator;
}, {}) as any;

export const COLORS: Colors = {
	white: '#FFFFFF',
	black: '#051B35',
	grey: '#939393',
	dark_grey: '#707070',
	light_grey: '#B8B7B7',
	shadow: '#00000029',
	blue1: '#47ACE1',
	blue2: '#75B5E3',
	blue3: '#64ACDF',
	light_blue1: '#E7F6FF',
	light_blue2: '#C8DBEC',
	background: '#F5F8FD',
	green: '#37B24A',
	error: '#FF4400',
};

export const theme: DefaultTheme = {
	colors: COLORS,

	grids: {
		sm: 8,
		md: 12,
		lg: 24,
	},

	//shadows
	shadow1: '#2F80ED',

	// media queries
	mediaWidth: mediaWidthTemplates,

	// css snippets
	flexColumnNoWrap: css`
		display: flex;
		flex-flow: column nowrap;
	`,
	flexRowNoWrap: css`
		display: flex;
		flex-flow: row nowrap;
	`,
};

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
	return <StyledComponentsThemeProvider theme={theme}>{children}</StyledComponentsThemeProvider>;
}

export const ThemedGlobalStyle = createGlobalStyle`
  html {
		font-size: 100%;

		${({ theme }) => theme.mediaWidth.upToSmall`
			font-size: 50%;
		`}
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'DM Sans', sans-serif;
		font-size: 1.5rem;
		line-height: 120%;
  }

	h1 {
		font-size: 5rem;
	}

	h2 {
		font-size: 4rem;
	}

	h3 {
		font-size: 3rem;
	}

	h4 {
		font-size: 2.25rem;
	}

	h5 {
		font-size: 1.5rem;
	}

	h1, h2, h3, h4, h5, p, span {
		line-height: 120%;
		margin: 0;
	}

	a {
		line-height: 100%;
	}

  select, button {
    font-family: 'DM Sans', sans-serif;
    outline: none;
    cursor: pointer;
  }
	
	input[type=number]::-webkit-inner-spin-button {
		opacity: 1;
	}

  select::-ms-expand {
      display: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  * {
    box-sizing: border-box;
  }
`;
